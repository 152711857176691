.menu{
    background: #07011F;
    height: 100vh;
    position: fixed;
    width: 23%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 1rem;
    color: #fff;
}

.user
{
    display: flex;
    padding: 1rem .2rem;
    align-items: center;
    cursor: pointer;
    gap: 1.2rem;
}

.details
{
    display: flex;
    flex-direction: column;
    gap: .1rem;
}

.username
{
    font-size: 1.3rem;
    font-weight: 500;
}

.about
{
    font-size: 1rem;
}

.search{
    padding: 0 1rem;
    width: 100%;
    height: 2rem;
    border: none;
    outline: none;
    background: transparent;
    border-bottom: 2px solid grey;
}

.search::placeholder
{
    color: lightgray;
    font-size: 1rem;
}

.channel
{
    display: flex;
    gap: .7rem;
    align-items: center;
    color: #fff;
    padding: .4rem 1rem;
    cursor: pointer;
}

.channel:hover
{
    background: #fe8e04;
}

.icon
{
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.icon img
{
    border-radius: 50%;
    object-fit: cover;
}

.channel p
{
    font-size: .9rem;
    font-weight: bold;
}
