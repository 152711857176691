.homepage
{
    display: flex;
}

.homepage::-webkit-scrollbar
{
  display: none;
  color: #fe8e04;
}

.profile-div
{
    width: 65%;
    height: 100%;
}

.profile-card
{
    position: fixed;
    overflow: hidden;
    width: 770px;
    height: 550px;
    border-radius: .4rem;
    display: flex;
    flex-direction: column;
    background: #07011F;
    top: 50%;   
    left: 50%;  /* Position it 50% from the left */
    transform: translate(-50%, -50%);
    border: 2px solid #fe8e04;
    z-index: 10;
}

.profile-title
{
    height: 6rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.profile-title h2 {
    padding: 0 .4rem;
    color: #fff;
    font-size: 2.5rem;
    font-stretch: extra-expanded;
}

.close
{
    cursor: pointer;
    margin-right: 1rem;
    color: #fe8e04;
    font-size: 2rem;
}

.profile-body
{
    display: flex;
    width: 100%;
    height: 100%;
}

.profile-bar
{
    width: 35%;
    height: 100%;
    color: #fff;
    padding:0 .19rem;
}

.profile-menu li
{
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    width: 100%;
    cursor: pointer;
}

.profile-icon
{
    font-size: 3rem;
}

.profile-menu li p
{
    font-size: 1.1rem;
    font-weight: 500;
}


.profile-menu li:hover
{
    background: #fe8e04;
    color: #07011F;
}


.profile-area
{
    width: 65%;
    height: 100%;
    padding: 1rem 2rem;
    background: lightgrey;
}

.profile-area h1
{
    font-size: 1.9rem;
    font-weight: 490;
}

.profile-details
{
    display: flex;
    flex-direction: column;
    padding:0 1.4rem;
    gap: 1rem;
}

.profile-details li h2
{
    font-size: 1.6rem;
    font-weight: 500;
}

.profile-details li p
{
    font-size: 1.3rem;
}









.edit-profile
{
    width: 65%;
    height: 100%;
    padding: 1rem 2rem;
    background: lightgrey;
}

.edit-profile h1
{
    font-size: 1.9rem;
    font-weight: 490;
}


.profile-form
{
    display: flex;
    flex-direction: column;
    padding:0 1.4rem;
    gap: .7rem;
}


.profile-input
{
    width: 100%;
    height: 3rem;
    background: transparent;
    padding: 0 1.2rem;
    outline: none;
    border-bottom: 2.9px solid #555;
}

.profile-input::placeholder
{
    font-size: 1.07rem;
    color: #333;
}


.profile-submit
{
    padding: .9rem 1.2rem;
    cursor: pointer;
    width: 8.6rem;
    font-weight: 550;
    border-radius: .5rem;
    margin: .4rem auto;
    color: #fe8e04;
    transition: .4s ease;
    background: #07011F;
}

.profile-submit:hover
{
    background: #fe8e04;
    color: #02021f;
    transition: .4s ease;
}






.profile-comp
{
    width: 100vw;
    height: 100vh;
    position: fixed;
    backdrop-filter: blur(10px);
    display: block;
    z-index: 10;
}

.profile-hidden
{
    display: none;
}

