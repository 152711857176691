
.landing-page
{
  padding: 3.5rem 5rem;
  height: 100vh;
  width: 100%;
  color: #fff;
  background: #07011F;
  overflow: hidden;
}

.nav-bar{
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 4rem;
}


.logo-mic{
background: #fff;
border-radius: 50%;
width: 26px;
height: 27px;
}

.name
{
  font-weight: bolder;
  display: flex;
  align-items: center;
  font-size: 2.2rem;
}
.name span{
  color: #C86F1E;

}

.login-link{
  font-size: 1rem;
  color: #07011F;
  margin-left: 6rem;
  padding: .5rem 1rem;
  font-weight: bold;
  background: #C86F1E;
  border: 3px solid #C86F1E;
  border-radius: 4rem;
}

.login-link:hover{
  background: transparent;
  color: #C86F1E;
  transition: background .5s ease, color .5s ease;
}

.body{
  display: flex;
  height: 100%;
}

.landing-title{
  padding-left: 1rem;
  padding-top: 4rem;
  line-height: 4.5rem;
  width: 60%;
  height: 100%;
 }

.landing-title h1
{
  font-weight: 400;
  font-size: 3.5rem;
  margin-bottom: 3rem;
}

.landing-title h1 .connect{
  font-weight: 100;
}

.landing-title h1 .podcast{
  color: #C86F1E;
}

.signup-link{
  font-size: 1rem;
  color: #07011F;
  margin-left: 6rem;
  padding: .8rem 1rem;
  font-weight: bold;
  background: #C86F1E;
  border-radius: 4rem;
  border: 3px solid #C86F1E;

}

.signup-link:hover{
  background: transparent;
  color: #C86F1E;
  transition: background .5s ease, color .5s ease;
}



.image
{
  width: 45%;
  display: grid;
  place-items: center;
}

.circle{
 background: #C86F1E;
 width: 260px;
 height: 260px;
 border-radius: 50%;
}

.div
{
  position: absolute;
  width: 320px;
  height: 320px;
  bottom: 12rem;
  right: 13rem;
  transform: rotate(-18deg);
  border: 2px solid gray;
}

.micimg
{
  transform: translateY(-160px);
  scale: .9;
}

.playimg
{
  transform: translateY(-140px);
}

.num1{
  font-size: 70px;
  font-weight: 100;
}
.num2{
  font-size: 60px;
  font-weight: 400;
}

.num3{
  font-size: 60px;
  font-weight: 600;
  color: #C86F1E;
}
.num4{
  font-size: 60px;
  font-weight: 400;
}
.num5{
  font-size: 73px;
  font-weight: 800;
}

