.messages
{
  background: grey;
  height: calc(100%-20rem);
  padding: .95rem;
}

.messages::-webkit-scrollbar
{
  display: none;
  color: #fe8e04;
}


.message
{
  z-index: -4;
  display: flex;
  gap: 1.25rem;
  margin-bottom: 1.25rem;
}

.Owner
{
  display: flex;
  flex-direction: row-reverse;
  gap: 1.25rem;
  margin-bottom: 1.25rem;
}


.Owner .messageContent
{
  align-items: flex-end; 
  max-width: 80%;
  display: flex;
  flex-direction: column;
  gap: .63rem;
}
.Owner p
{
  max-width: max-content;
  color: #07011F;
  padding: .625rem 1.25rem;
  border-radius: 0.63rem 0 .63rem .63rem;
  background: #fe8e04;
}



.messageInfo
{
  padding-right: .6rem;
  display: flex;
  flex-direction: column;
  color: #07011F;
  font-weight: 3000;
}

.messageInfo img
{
  border-radius: 50%;
  object-fit: cover;
  width: 3rem;
  height: 3rem;
}

.messageContent
{
  align-items: flex-start; 
  max-width: 80%;
  display: flex;
  flex-direction: column;
  gap: .63rem;
}
.message p
{
  max-width: max-content;
  color: #fe8e04;
  padding: .625rem 1.25rem;
  border-radius: 0 0.63rem .63rem .63rem;
  background: #07011F;
}


.messageContent img 
{
  width: 40%;
}


.inputArea
{
  box-shadow: -15px 10px 30px black;
   color: #fff;
  border-top: 1.8px solid grey;
  height: 4.7rem;
  width: 100%;
  /* position: fixed; */
  position: sticky;
  bottom: 0;
  align-items: center;
  padding: .5rem;
  background: lightgrey;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inputbox
{
  color: #000;
  padding: 0 1.4rem;
  background: lightgrey;
  outline: none;
  height: 70%;
  width: 70%;
  border-bottom: 3px solid grey;
  font-size: 1.3rem;
  transition: .8s ease;
  
}

.inputbox::placeholder
{
    font-size: 1rem;
    color: #000;
    font-weight: 580;
}

.sendicons
{
  display: flex;
  gap: 1.9rem;
  align-items: center;
  padding: .2rem;
  margin-right: 4rem;
}


.sendicon
{
  cursor: pointer;
  border-radius: 50%;
  padding: .4rem;
  background: #07011F;
  border: 2.7px solid #07011F;
  color: white;
  width: 2.3rem;
  height: 2.3rem;
  transition: .5s ease;
}

.sendicon:hover
{
  background: transparent;
  color: #07011F;
}


@media (max-width:800px) {
 .sendicons
 {
  gap: .9rem;
  padding: .1rem;
 } 

.sendicon{
  height: 2.3rem;
}
}
