*{
    margin: 0;
    padding: 0;
}
.main{
    height: 100vh;
    width: 100%;
    background-image: url(../podclub-images/background.png);
    background-size: cover;
    overflow: hidden;
    font-family: 'K2D', Lato;
}
.sub-title{
    
    font-size: 27px;
    font-weight: 400;
}


.form-container{
    width: 411px;
    height: 554px;
    background-color: rgba(200,111,30,0.65);
    border-radius: 75px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo{

    position: relative;
    margin-top: 30px;
    margin-left: 79px;
}


.login-input {
    margin-bottom: 30px;
    width: 308px;
    height: 58px;
    border-radius: 60px;
    display: flex;
    border: 2px solid;
    border-color: #07011F;
    background-color: rgba(0,0,0,0);
    font-size: 21px;
    text-align: center;
    font-weight: 100;
    color: #FFFFFF;
}

.login-input::placeholder
{
    color: #fff;
}
.login-btn {
    background: #020220;
    color: #fff;
    border: none;
    padding: 10px 20px;
    display: inline-block;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 20px;
    width: 208px;
    height: 58px;
    text-transform: uppercase;
    cursor: pointer;
    transform: skew(-21deg);
    border-radius: 3rem;
  }
  
.login-btn span {
    display: inline-block;
    transform: skew(21deg);
  }
  
  .login-btn::before {
    content: '';
    position: absolute;
    border-radius: 3rem;
    top: 0;
    bottom: 0;
    right: 100%;
    left: 0;
    background: #fff;
    opacity: 0;
    z-index: -1;
    transition: all 0.5s;
  }
  
  .login-btn:hover {
    color: #07011F;
    transition: .3s ease;
  }
  
  .login-btn:hover::before {
    left: 0;
    right: 0;
    opacity: 1;
  }

.title{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #FFFFFF;
    font-size: 63px;
    margin-bottom: 38px;
    font-weight: 800;
}

.redirect{
    color: #FFFFFF;
    font-size: 13px;
    font-weight: 400;
}




.signup{
    font-size: 15px;
    color: #020220;
    font-weight: 600;
}

img{
    position: relative;
    margin-top: 48px;
}


.Login-link{
    color: blue;
    font-size: 15px;
}
